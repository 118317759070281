import React from "react";

const EmailSVG = () => (
  <svg
    width="127"
    height="96"
    viewBox="0 0 127 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <g filter="url(#filter0_d)">
        <path
          d="M107.234 21.6406H1.76562V93.3594H107.234V21.6406Z"
          fill="#D6E3F0"
        />
        <path
          d="M2.1874 22.0624L54.5 65.9375L106.812 22.0624M1.76562 21.6406H107.234V93.3594H1.76562V21.6406Z"
          stroke="#92B0CE"
          strokeWidth="3.5"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_d)">
        <path
          d="M125.079 20.5072C125.079 30.768 116.761 39.0861 106.5 39.0861C96.239 39.0861 87.9209 30.768 87.9209 20.5072C87.9209 10.2463 96.239 1.92822 106.5 1.92822C116.761 1.92822 125.079 10.2463 125.079 20.5072Z"
          fill="#FFE099"
        />
        <path
          d="M106.5 23.0625V10.25M106.5 30.75V26.9063M125.079 20.5072C125.079 30.768 116.761 39.0861 106.5 39.0861C96.239 39.0861 87.9209 30.768 87.9209 20.5072C87.9209 10.2463 96.239 1.92822 106.5 1.92822C116.761 1.92822 125.079 10.2463 125.079 20.5072Z"
          stroke="#CA9008"
          strokeWidth="3"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-3.98438"
        y="17.8906"
        width="116.969"
        height="83.2187"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0666667 0 0 0 0 0.168627 0 0 0 0 0.266667 0 0 0 0.18 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="82"
        y="-2"
        width="49"
        height="49"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0666667 0 0 0 0 0.168627 0 0 0 0 0.266667 0 0 0 0.18 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect width="127" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmailSVG;
