import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import TVGConf from "@tvg/conf";
import ErrorPage from "@tvg/atomic-ui/_templates/ErrorPage";
import parseCapiMessage from "@tvg/utils/capiUtils";
import isMobile from "@tvg/utils/mobileUtils";
import * as splashBuilder from "./splashBuilder";
import ErrorPageContainer from "./styled-components";

export class LocationSplashComponent extends Component {
  static defaultProps = {
    isOpen: false,
    isLocationRequired: false,
    isLoading: false,
    onLocationGet: null,
    className: "",
    showClose: false,
    customerServicesUrl: "",
    loginErrorMessages: null,
    rgTimeoutEndDate: "",
    androidBlockedStateMessages: {}
  };

  buildErrorPageProps() {
    const { brand } = TVGConf();
    const isApp = isMobile(TVGConf().product);
    const TIMEOUT_END_DATE_REGEX = /#EXCLUSION_END_DATE/gi;
    switch (this.props.error) {
      case "PERMISSION_DENIED":
        return splashBuilder.buildLocationDeniedSplash(
          this.props.dispatch,
          brand,
          this.props.customerServicesUrl,
          isApp
        );

      case "UNSUPPORTED":
        return splashBuilder.buildLocationUnsupportedSplash(
          this.props.dispatch,
          brand,
          this.props.customerServicesUrl,
          isApp
        );

      case "POSITION_UNAVAILABLE":
        return splashBuilder.buildPositionUnavailableSplash(
          this.props.dispatch,
          brand,
          this.props.customerServicesUrl,
          isApp
        );

      case "TIMEOUT":
        return splashBuilder.buildTimedOutSplash(
          this.props.dispatch,
          this.props.geolocation,
          this.props.isLoading,
          this.props.onLocationGet,
          brand,
          this.props.customerServicesUrl,
          isApp
        );

      case "UNKNOWN_ERROR":
        return splashBuilder.buildUnknownErrorSplash(
          this.props.dispatch,
          this.props.geolocation,
          this.props.isLoading,
          this.props.onLocationGet,
          brand,
          this.props.customerServicesUrl,
          isApp,
          this.props.showClose
        );

      case "UNAUTHORIZED":
        return splashBuilder.buildUnauthorizedLocationSplash(
          this.props.dispatch,
          this.props.geolocation,
          this.props.isLoading,
          brand,
          this.props.customerServicesUrl,
          isApp
        );

      case "RG_TIMEOUT":
        return splashBuilder.buildTimeoutExclusionSplash(
          brand,
          this.props.customerServicesUrl,
          isApp,
          get(this.props.loginErrorMessages, "rgAccountInTimeout.title"),
          get(
            this.props.loginErrorMessages,
            "rgAccountInTimeout.text",
            ""
          ).replace(TIMEOUT_END_DATE_REGEX, `${this.props.rgTimeoutEndDate}`),
          "timer"
        );

      case "RG_SELF_EXCLUSION":
        return splashBuilder.buildTimeoutExclusionSplash(
          brand,
          this.props.customerServicesUrl,
          isApp,
          get(this.props.loginErrorMessages, "rgAccountInSelfExclusion.title"),
          get(this.props.loginErrorMessages, "rgAccountInSelfExclusion.text"),
          "calendar"
        );

      case "RG_SUSPEND":
        return splashBuilder.buildTimeoutExclusionSplash(
          brand,
          this.props.customerServicesUrl,
          isApp,
          get(this.props.loginErrorMessages, "rgAccountInSuspension.title"),
          get(this.props.loginErrorMessages, "rgAccountInSuspension.text"),
          "lock"
        );
      case "BLOCKED_COUNTRY":
        return splashBuilder.buildBlockedCountrySplash(
          this.props.dispatch,
          brand,
          this.props.isLoading
        );
      case "ANDROID_BLOCKED_STATE":
        return splashBuilder.buildAndroidBlockedState(
          this.props.androidBlockedStateMessages,
          this.props.dispatch,
          brand,
          this.props.isLoading
        );

      default:
        return splashBuilder.buildAllowLocationSplash(
          this.props.dispatch,
          this.props.geolocation,
          this.props.isLoading,
          this.props.onLocationGet,
          brand,
          this.props.customerServicesUrl,
          isApp
        );
    }
  }

  render() {
    if (this.props.isOpen && this.props.isLocationRequired) {
      return (
        <ErrorPageContainer className={this.props.className}>
          <ErrorPage {...this.buildErrorPageProps()} />
        </ErrorPageContainer>
      );
    }
    return null;
  }
}

export default connect(
  (store) => ({
    isOpen: store.locationSplash.isOpen,
    isLocationRequired: store.locationSplash.isLocationRequired,
    isLoading: store.locationSplash.isLoading,
    error: store.locationSplash.error,
    onLocationGet: store.locationSplash.onLocationGet,
    showClose: store.locationSplash.showClose,
    customerServicesUrl: get(
      store,
      "capi.messages.globalSupportLink",
      "https://support.tvg.com"
    ),
    loginErrorMessages: parseCapiMessage(
      store,
      "capi.messages.loginErrorMessages"
    ),
    rgTimeoutEndDate: get(store, "locationSplash.timeoutEndDate"),
    androidBlockedStateMessages: parseCapiMessage(
      store,
      "capi.messages.androidBlockedStatesMessages"
    )
  }),
  (dispatch) => ({ dispatch })
)(LocationSplashComponent);
