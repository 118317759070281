import styled from "styled-components";

export default styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;

  & .rgErrorPageMainContent {
    & > div:first-of-type {
      justify-content: center;
    }

    & h1 {
      text-transform: inherit;
    }

    & > div:last-of-type {
      max-width: none;
    }
  }
`;
